import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Grid, Layout as LayoutAnt, Menu, theme } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import LOGO from "assets/images/logo.svg";
import { Button, Flex, Image } from "components";
import { PopupNotify } from "components/popupNotify";
import { PATHS, ROUTES } from "constants/routes";
import { authStorage } from "helpers/localStorage";
import { useAsync } from "hooks/useAsync";
import { AuthAction, useAuth } from "providers/auth";
import { useLayout } from "providers/layout";
import { apiAdminLogout } from "services/admin";
import "./index.css";
const { Header, Sider, Content } = LayoutAnt;

export const Layout: React.FC = () => {
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigator = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch({
      type: AuthAction.clearAuth,
      payload: undefined,
    });
    navigate(PATHS.login);
    authStorage.remove("token");
  }, [dispatch, navigate]);

  const [executeLogout] = useAsync(apiAdminLogout, {
    onSuccess() {
      handleLogout();
    },
    onFailed() {
      handleLogout();
    },
  });

  const { action } = useLayout();

  const keyActive = useMemo(
    () =>
      ROUTES.filter((item) => !item.hideMenu).findIndex((x) =>
        location.pathname.includes(x.href)
      ),
    [location.pathname]
  );

  // const { fnCheck } = useCheckPermission();
  const { md } = Grid.useBreakpoint();
  const [openMobile, setOpenMobile] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);

  return (
    <LayoutAnt hasSider style={{ height: "100vh" }}>
      <PopupNotify
        open={openNotify}
        handleCancel={() => setOpenNotify(false)}
      />
      <Sider
        style={
          !md && openMobile
            ? {
                left: -200,
                position: "fixed",
              }
            : undefined
        }
        trigger={null}
        collapsible
        collapsed={!md ? false : collapsed}
      >
        <Flex height={"100vh"} flexDirection="column">
          <Flex justifyContent={"center"} margin="20px 0 10px 0">
            <div style={{ maxWidth: 159, width: "100%" }}>
              <Link to={"/"}>
                <Image src={LOGO} ratio="ratio42x159" />
              </Link>
            </div>
          </Flex>
          <Flex
            className="header-menu"
            display={"block"}
            flexGrow={1}
            overflow="auto"
          >
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[keyActive?.toString()]}
              items={ROUTES.filter(
                (item) =>
                  !item.hideMenu && (item.role === "SUPER_ADMIN")
              ).map((item, key) => ({
                key: key,
                icon: React.createElement(item.icon),
                label: item.title,
                onClick: () => {
                  if (!md) {
                    setOpenMobile(true);
                  }
                  navigator(item.href);
                },
              }))}
            />
          </Flex>
          <Flex display={"block"} flexShrink={0}>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={[
                {
                  key: "logout",
                  icon: <LogoutOutlined />,
                  label: "Đăng xuất",
                  onClick: executeLogout,
                },
              ]}
            />
          </Flex>
        </Flex>
      </Sider>
      <LayoutAnt className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 2,
            boxShadow: "0px 0px 5px 5px #999999",
          }}
        >
          <div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: () => {
                  if (!md) {
                    setOpenMobile((prev) => !prev);
                  } else {
                    setCollapsed(!collapsed);
                  }
                },
                style: {
                  fontSize: "20px",
                  marginLeft: "20px",
                },
              }
            )}
          </div>
          <Flex marginRight={20}>
            <Button
              // disabled={!fnCheck(["notification.create", "user.get_list"])}
              style={{ marginRight: 20 }}
              type="primary"
              icon={<NotificationOutlined />}
              onClick={() => setOpenNotify(true)}
            />
            {action}
          </Flex>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              flex: 1,
              padding: 20,
              background: colorBgContainer,
              position: "relative",
            }}
          >
            <Outlet />
          </div>
        </Content>
      </LayoutAnt>
    </LayoutAnt>
  );
};
