import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import {
  Modal,
  Space,
  Table as TableAnt,
  TablePaginationConfig,
  Typography,
} from "antd";
import { Button } from "components";
import useSearch from "hooks/useSearch";
import { useRef, useState } from "react";

interface TableProps<T> {
  columns: any;
  dataSource: any;
  pagination?: TablePaginationConfig;
  hidePagination?: boolean;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  moreFunction?: {
    text: string;
    fn: (e: T) => void;
  };
  readonlyDelete?: boolean;
  readonlyUpdate?: boolean;
}

const { Text } = Typography;

export const Table = <T,>({
  columns,
  dataSource,
  pagination,
  hidePagination,
  onDelete,
  onEdit,
  moreFunction,
  readonlyDelete,
  readonlyUpdate,
}: TableProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const keyRef = useRef<string>();
  const { params, setPage } = useSearch();

  return (
    <>
      <Modal
        title="Thông báo"
        open={isModalOpen}
        onOk={() => {
          if (keyRef.current && onDelete) {
            onDelete(keyRef.current);
          }
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <Text>Bạn có muốn xoá phần tử này không?</Text>
      </Modal>
      <div style={{ overflow: "auto" }}>
        <TableAnt
          columns={
            onDelete || onEdit || moreFunction
              ? [
                  ...columns,
                  {
                    title: "Chức năng",
                    key: "action",
                    render: (_, record) => {
                      return (
                        <Space size="middle">
                          {moreFunction && (
                            <Button
                              type="primary"
                              ghost
                              onClick={() => {
                                moreFunction.fn(_);
                              }}
                              disabled={readonlyUpdate}
                            >
                              {moreFunction.text}
                            </Button>
                          )}
                          {onDelete && (
                            <Button
                              onClick={() => {
                                keyRef.current = record.id?.toString();
                                setIsModalOpen(true);
                              }}
                              icon={<DeleteOutlined />}
                              disabled={readonlyDelete}
                            />
                          )}
                          {onEdit && (
                            <Button
                              onClick={() => onEdit(record.id as string)}
                              icon={<EditOutlined />}
                              disabled={readonlyUpdate}
                            />
                          )}
                        </Space>
                      );
                    },
                  },
                ]
              : columns
          }
          dataSource={dataSource}
          pagination={
            hidePagination
              ? false
              : {
                  showLessItems: true,
                  showSizeChanger: true,
                  defaultPageSize: 10,
                  current: params.page,
                  ...pagination,
                  onChange: (page) => {
                    setPage(page);
                  },
                }
          }
        />
      </div>
    </>
  );
};
